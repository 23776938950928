import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

// import "../utils/scripts/smoothScroll"

// import articlesData from "../data/Articles.json"
import Header from "../components/MainPage/Header"
import Footer from "../components/MainPage/Footer"
import "../utils/css/MainPage/footer.css"


const BlogGrid = () => (
    // console.log(articlesData)
    <StaticQuery
        query={graphql`
        query blogTileImages {
            allArticlesJson {
              nodes {
                path
                title
                intro
                cover {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `
        }
        render = { articlesData => {
            // console.log(articlesData.allArticlesJson.nodes, typeof articlesData.allArticlesJson.nodes)
            return(<div className="articleTiles">
                    { articlesData.allArticlesJson.nodes.map(function(data, i) {
                        // console.log(data.path)
                        return(
                            <Link to={data.path}><div className="tile">
                                <div className="introPic">
                                    <Img fluid={ data.cover.childImageSharp.fluid } />
                                </div>
                                <div className="articleTitle">
                                    { data.title }
                                </div>
                                <div className="introText">
                                    { data.intro }
                                </div>
                            </div></Link>
                        )
                    }) }
                </div>
            )
        }}
    />
)
                    

export default () => (
    <div>
        <Header />
        <BlogGrid />
        <Footer />
    </div>
)