import React from "react"
import { Link } from "gatsby"

import NavbarNormal from "../Navigation/NavbarNormal"
import NavbarMobile from "../Navigation/NavbarMobile"
import fogLogo from '../../../static/icons/FOG_logo.svg';


export default function Header() {
    return(
        <header className="header">
            {/* <img className="logo" src={ ITLLogo } /> */}
            <a href="/"><img className="logo" src={ fogLogo } alt="Future of Gaming"/></a>
            <NavbarNormal page="" />
            <NavbarMobile page="" />
        </header>
    )
}
